export class ApiConstant {
  static readonly API_ENTITY = {
    AREAS: 'areas',
    COMPANIES: 'companies',
    DEVICE: 'devices',
    FILES: 'files',
    ROLES: 'roles',
    STATIONS: 'stations',
    TRAIN_STATIONS: 'train-stations',
    USERS: 'users',
  } as const;

  static readonly LOOPBACK_FILTER = {LIMIT_DEFAULT: 10, LIMIT_MAX_SIZE: 100, SKIP_DEFAULT: 0};
}
