export class ModalConstant {
  static readonly ENTITY = {
    AREA: 'Khu vực',
    COMPANY: 'Đơn vị',
    PASSWORD: 'Mật khẩu',
    STATION: 'Lý Trình',
    TRAIN_STATION: 'Nhà ga',
    USER: 'Người dùng',
  } as const;

  static readonly NAME = {
    AREA_CREATE: 'areaCreate',
    AREA_DELETE: 'areaDelete',
    AREA_UPDATE: 'areaUpdate',
    COMPANY_CREATE: 'companyCreate',
    COMPANY_DELETE: 'companyDelete',
    COMPANY_UPDATE: 'companyUpdate',
    STATION_CREATE: 'stationCreate',
    STATION_UPDATE: 'stationUpdate',
    TRAIN_STATION_CREATE: 'trainStationCreate',
    TRAIN_STATION_DELETE: 'trainStationDelete',
    TRAIN_STATION_UPDATE: 'trainStationUpdate',
    USER_CREATE: 'userCreate',
    USER_DELETE: 'userDelete',
    USER_DETAIL: 'userDetail',
    USER_UPDATE: 'userUpdate',
    USER_UPDATE_MY_PASSWORD: 'userUpdateMyPassword',
    USER_UPDATE_MY_PROFILE: 'userUpdateMyProfile',
  } as const;
}
