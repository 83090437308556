import {CommonConstant, RoleConstant} from '@rcms/constants';
import {TEntity, TPermissionsData, TRoleName, TRolePermissions} from '@rcms/types';
import {cloneDeep as _cloneDeep} from 'lodash-es';

const permissionsDataDefault: TPermissionsData = {
  [CommonConstant.ENTITY.ALARM]: {read: false, update: false},
  [CommonConstant.ENTITY.AREA]: {create: false, delete: false, read: false, update: false},
  [CommonConstant.ENTITY.COMPANY]: {create: false, delete: false, read: false, update: false},
  [CommonConstant.ENTITY.DEVICE]: {read: false, update: false},
  [CommonConstant.ENTITY.OTA]: {create: false, read: false, update: false},
  [CommonConstant.ENTITY.ROLE]: {read: false},
  [CommonConstant.ENTITY.STATION]: {
    create: false,
    delete: false,
    read: false,
    read_event: false,
    read_simulation: false,
    read_specification: false,
    update: false,
  },
  [CommonConstant.ENTITY.TRAIN_STATION]: {read: false},
  [CommonConstant.ENTITY.USER]: {create: false, delete: false, read: false, update: false},
};

const roleNameDisplayMapping: Record<TRoleName, string> = {
  [RoleConstant.ROLE_NAME.ADMINISTRATOR]: 'Quản trị',
  [RoleConstant.ROLE_NAME.MAINTENANCE]: 'Xí nghiệp',
  [RoleConstant.ROLE_NAME.OBSERVER]: 'Cầu đường',
  [RoleConstant.ROLE_NAME.SUPERVISOR]: 'Giám sát',
};

export class RoleUtil {
  static getPermissionsData(permissions: TRolePermissions): TPermissionsData {
    const permissionsData = RoleUtil.getPermissionsDataDefault();

    (Object.keys(permissionsData) as TEntity[]).forEach(entity => {
      (permissions[entity] ?? []).forEach(permission => permissionsData[entity][permission] = true);
    });

    return permissionsData;
  }

  static getPermissionsDataDefault(): TPermissionsData {
    return _cloneDeep(permissionsDataDefault);
  }

  static getRoleNameDisplay(roleName: TRoleName): string {
    return roleNameDisplayMapping[roleName];
  }
}
