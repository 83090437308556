import {inject, Injectable} from '@angular/core';
import {DeviceApi} from '@rcms/apis';
import {ICountResponse, IDeviceResponse, ILoopBackFilters} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DeviceService {
  private deviceApi = inject(DeviceApi);

  getAllDevicesObs(filters: ILoopBackFilters): Observable<IDeviceResponse[]> {
    return this.deviceApi.getAllDevices(filters);
  }

  getCountAllDevicesObs(where: object): Observable<ICountResponse> {
    return this.deviceApi.getCountAllDevices(where);
  }
}
