export class ErrorConstant {
  static readonly ERROR_CODE_API = {
    DATA_EXIST: 'DATA_EXIST',
    DATA_SYSTEM: 'DATA_SYSTEM',
    DATA_USED: 'DATA_USED',
    LOGIN_USERNAME_PASSWORD: 'LOGIN_USERNAME_PASSWORD',
    NOT_FOUND_ENTITY: 'NOT_FOUND_ENTITY',
    USER_NOT_ACTIVE: 'USER_NOT_ACTIVE',
  } as const;

  static readonly ERROR_FLAG = {
    ADDRESS_EXIST: 'addressExist',
    AREA_NOT_FOUND: 'areaNotFound',
    COMPANY_NOT_FOUND: 'companyNotFound',
    COMPANY_MAINTENANCE_NOT_FOUND: 'companyMaintenanceNotFound',
    COMPANY_OBSERVER_NOT_FOUND: 'companyObserverNotFound',
    DEVICE_NOT_FOUND: 'deviceNotFound',
    DEVICE_USED: 'deviceUsed',
    NAME_EXIST: 'nameExist',
    ROLE_NOT_FOUND: 'roleNotFound',
    USERNAME_EXIST: 'usernameExist',
  } as const;
}
