import {HelperConstant} from '@rcms/constants/helper.constant';

export class CommonConstant {
  static readonly DEBOUNCE_TIME_MS = 400;

  static readonly ENTITY = HelperConstant.ENTITY;

  static readonly FEEDBACK = {
    ACTION: {CREATE: 'CREATE', DELETE: 'DELETE', UPDATE: 'UPDATE'},
    MESSAGE: {CREATE: 'Tạo mới', DELETE: 'Xoá', FAILED: 'thất bại', SUCCESS: 'thành công', UPDATE: 'Cật nhật'},
    STATE: {ERROR: 'error', INFO: 'info', SUCCESS: 'success', WARNING: 'warning'},
  } as const;

  static readonly FIELD = {
    ACTIVE: 'active',
    ADDRESS: 'address',
    AREA_ID: 'areaId',
    COMPANY_ID: 'companyId',
    COMPANY_MAINTENANCE_ID: 'companyMaintenanceId',
    COMPANY_OBSERVER_ID: 'companyObserverId',
    DESCRIPTION: 'description',
    DEVICE_ID: 'deviceId',
    FULL_NAME: 'fullName',
    IS_TRAIN_STATION: 'isTrainStation',
    NAME: 'name',
    PASSWORD: 'password',
    PASSWORD_NEW: 'passwordNew',
    PASSWORD_NEW_CONFIRM: 'passwordNewConfirm',
    PASSWORD_OLD: 'passwordOld',
    ROLE_ID: 'roleId',
    USERNAME: 'username',
  } as const;

  static readonly LIMIT = {LENGTH_64: 64, LENGTH_256: 256, LENGTH_512: 512, LENGTH_1024: 1024} as const;

  static readonly PAGING = {ITEMS_PER_PAGE: [5, 10, 20, 30, 50, 100], START_PAGE_DEFAULT: 1} as const;
}
